import { Component, OnInit } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { TimeService } from '../time.service';
import { Blog } from './blog';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-blog-home',
  templateUrl: './blog-home.component.html',
  styleUrls: ['./blog-home.component.css']
})
export class BlogHomeComponent implements OnInit {
  public blogs: Blog[] = [];
  public featuredBlog: Blog = new Blog();
  public blogItems: Observable<Blog[]>
  public blogOverflow: boolean = false;

  constructor(private timeService: TimeService, private storage: AngularFireStorage,
              private afs: AngularFirestore) {
    this.initializeBlogs();
    this.initializeFeaturedBlog();
  }

  ngOnInit() {
  }

  private initializeBlogs() {
    let blogResults = this.afs.collection('blogPosts', x => x.where('featured', "==", false)).snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data() as Blog;
        const id = a.payload.doc.id;
        return { id, ...data };
      }))
    );
    
    let uriResult: string;
    blogResults.subscribe(actionArray => {
      this.blogs = [];

      this.blogOverflow = actionArray.length > 9;

      for (let i = 0; i < 9; i++){
        let blog = new Blog();

        blog.blogId = actionArray[i]['id'];
        blog.blogTitle = actionArray[i]['BlogTitle'];
        blog.blogDescription = actionArray[i]['BlogDescription'].substring(0, 50) + "...";
        blog.blogAuthor = actionArray[i]['BlogAuthor'];
        blog.blogTimeSince = this.timeService.getTime(new Date(actionArray[i]['BlogTimeSince']));
        uriResult = actionArray[i]['BlogImageUri'];

        const ref = this.storage.ref(actionArray[i]['BlogImageUri']);
        ref.getDownloadURL().subscribe(
          url => blog.blogImageUri = url
        );

        this.blogs.push(blog);
      }
    }); 
  }

  private initializeFeaturedBlog() {
    let featuredBlogResult = this.afs.collection('blogPosts', x => x.where('featured', "==", true)).snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data() as Blog;
        const id = a.payload.doc.id;
        return { id, ...data };
      }))
    );
    
    featuredBlogResult.subscribe(actionArray => {
      if (actionArray.length != 1){
        this.featuredBlog = null;
        return;
      }

      this.featuredBlog.blogId = actionArray[0]['id'];
      this.featuredBlog.blogTitle = actionArray[0]['BlogTitle'];
      this.featuredBlog.blogAuthor = actionArray[0]['BlogAuthor'];
      this.featuredBlog.blogDescription = actionArray[0]['BlogDescription']
      this.featuredBlog.blogTimeSince = this.timeService.getTime(new Date(actionArray[0]['BlogTimeSince']));

      const ref = this.storage.ref(actionArray[0]['BlogImageUri']);
      ref.getDownloadURL().subscribe(
        url => this.featuredBlog.blogImageUri = url
      ); 
    });  
  }
}
