// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false, 
  firebase: {
    apiKey: "AIzaSyBXNDG6Dv9oEFjJq_xSlF_o_2th9f2FdR4",
    authDomain: "homepage-9cf66.firebaseapp.com",
    databaseURL: "https://homepage-9cf66.firebaseio.com",
    projectId: "homepage-9cf66",
    storageBucket: "homepage-9cf66.appspot.com",
    messagingSenderId: "210216323086",
    appId: "1:210216323086:web:dba021f07c4e25bd64d45f",
    measurementId: "G-4LSMC8D2SX"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
