import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { ToastService } from '../toast.service';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})
export class SignupComponent implements OnInit {

  constructor(private afAuth: AngularFireAuth, private toastService: ToastService) { }

  public signUp = {
    Email: "",
    Username: "",
    Password: ""
  }

  public register(){
    this.afAuth.auth.createUserWithEmailAndPassword(this.signUp.Email, this.signUp.Password).then(() => {
      this.toastService.show("Profile Created Successfully!", { classname: 'bg-success text-light' });
    }).catch(() => {
      this.toastService.show("Something went wrong with profile creation...", { classname: 'bg-danger text-light', delay: 15000 });
    });

    let user = this.afAuth.auth.currentUser;

    user.updateProfile({
      displayName: this.signUp.Username
    })
  }

  ngOnInit() {
  }

}
