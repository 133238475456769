import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-blog-featured-story',
  templateUrl: './blog-featured-story.component.html',
  styleUrls: ['./blog-featured-story.component.css']
})
export class BlogFeaturedStoryComponent implements OnInit {
  @Input() blogImageUri: string;
  @Input() blogTitle: string;
  @Input() blogDescription: string;
  @Input() blogAuthor: string;
  @Input() blogTimeSince: string;

  constructor() {
   }

  ngOnInit() {
  }

}
