import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { BlogHomeComponent } from './blog-home/blog-home.component';
import { AboutComponent } from './about/about.component'
import { LoginComponent } from './login/login.component';
import { SignupComponent } from './signup/signup.component';
import { BlogReaderComponent } from './blog-reader/blog-reader.component';

const routes: Routes = [
  {
    path: 'home',
    component: HomeComponent
  },
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full'
  },
  {
    path: 'blog',
    component: BlogHomeComponent,
    children: [
      {
        path: 'blogReader',
        component: BlogReaderComponent
      }
    ]
  },
  {
    path: 'about',
    component: AboutComponent
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'signup',
    component: SignupComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
