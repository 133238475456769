import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-blog-story',
  templateUrl: './blog-story.component.html',
  styleUrls: ['./blog-story.component.css']
})
export class BlogStoryComponent implements OnInit {
  @Input() blogImageUri: string;
  @Input() blogTitle: string;
  @Input() blogDescription: string;
  @Input() blogAuthor: string;
  @Input() blogTimeSince: string;

  constructor() { }

  ngOnInit() {
  }

}
