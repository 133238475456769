import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home-carousel',
  templateUrl: './home-carousel.component.html',
  styleUrls: ['./home-carousel.component.css']
})
export class HomeCarouselComponent implements OnInit {

  constructor() { }

  public images = ["../../assets/img/st-kitts_dark.jpg", "../../assets/img/chicago_dark.jpg", "../../assets/img/west-side_dark.jpg"]

  ngOnInit() {
  }

  public scrollToBottom() {
    let contactWindow = document.getElementById('contactUs').getBoundingClientRect();

    window.scrollTo({
      top: contactWindow.top,
      behavior: "smooth"
    });
  }

}
