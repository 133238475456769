import { Component, OnInit } from '@angular/core';
import { debug } from 'util';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  public images = ["../../assets/img/st-kitts_dark.jpg", "../../assets/img/chicago_dark.jpg", "../../assets/img/west-side_dark.jpg"]
  public toasts = ["abc"];
}
