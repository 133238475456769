import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TimeService {

  constructor() { }

  public getTime(date: Date){
    const MINUTE = 60000;
    const HOUR = 3600000;
    const TWENTY_FOUR_HOURS = 86400000;

    let millisecondsBetween = Date.now() - date.getTime();

    if (millisecondsBetween < MINUTE){
      return "A Moment Ago";
    } else if (millisecondsBetween < HOUR){
      let result = Math.floor(millisecondsBetween / MINUTE);
      if (result == 1){
        return result + " Minute Ago";
      } else {
        return result + " Minutes Ago";
      }
    } else if (millisecondsBetween < TWENTY_FOUR_HOURS){
      let result = Math.floor(millisecondsBetween / HOUR);
      if (result == 1){
        return result + " Hour Ago";
      } else {
        return result + " Hours Ago";
      }
    } else {
      return this.getDate(date);
    }
  }

  public getDate(date: Date): string {
    let month = this.getMonth(date.getMonth());
    let day = this.getDayOfWeek(date.getDay());

    return day + ", " + month + " " + date.getDate() + ", " + date.getFullYear()
  }

  public getMonth(month: number): string {
    switch (month) {
      case 0:
        return "January";
      case 1:
        return "February";
      case 2:
        return "March";
      case 3:
        return "April";
      case 4:
        return "May";
      case 5:
        return "June";
      case 6:
        return "July";
      case 7:
        return "August";
      case 8:
        return "September";
      case 9:
        return "October";
      case 10:
        return "November";
      case 11:
        return "December";
    }
  }

  public getDayOfWeek(day: number){
    switch(day){
      case 0:
        return "Sunday";
      case 1:
        return "Monday";
      case 2:
        return "Tuesday";
      case 3:
        return "Wednesday";
      case 4:
        return "Thursday";
      case 5:
        return "Friday";
      case 6:
        return "Saturday";
    }
  }
}
