import { Component, OnInit } from '@angular/core';
import { ToastService } from '../toast.service';
import { Contact } from '../contact';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit {
  private contactsCollection: AngularFirestoreCollection<Contact>;

  constructor(private toastService: ToastService, db: AngularFirestore) {
    this.contactsCollection = db.collection<Contact>('contacts');
  }

  ngOnInit() {
  }

  form = new FormGroup({
    firstName: new FormControl(''),
    lastName: new FormControl(''),
    email: new FormControl(''),
    company: new FormControl(''),
    summary: new FormControl('')
  });

  public submitForm() {
    let firstName = this.form.controls.firstName.value;
    let lastName = this.form.controls.lastName.value;
    let email = this.form.controls.email.value;
    let company = this.form.controls.company.value;
    let summary = this.form.controls.summary.value;
    if (firstName != "" && lastName != "" && email != "" && summary != "") {
      this.contactsCollection.add({
        FirstName: firstName,
        LastName: lastName,
        Email: email,
        CompanyName: company,
        Summary: summary,
      }).then(() =>{
        this.toastService.show("Contact request submitted successfully!", { classname: 'bg-success text-light' })
      }).catch(() => {
        this.toastService.show("Could not send request at this time. Try again later.", { classname: 'bg-danger text-light', delay: 15000 })
      })
      this.ngOnInit()
    }
  }

}
