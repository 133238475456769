import { Component, OnInit, Input } from '@angular/core';
import { HostListener } from '@angular/core'
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestoreCollection, AngularFirestore } from '@angular/fire/firestore';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.css']
})
export class ToolbarComponent implements OnInit {

  public open: boolean;

  public email: string = "justin.boey@330softworks.com";
  mobile = false;

  constructor(private router: Router, private route: ActivatedRoute,
    public afs: AngularFirestore) {
    this.mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  }

  ngOnInit(): void {
  }

  public openNav() {
    document.getElementById("mySidenav").style.width = "250px";
    this.open = true;
  }

  public closeNav() {
    document.getElementById("mySidenav").style.width = "0";
    this.open = false;
  }

  public goToTwitter() {
    window.location.href = "https://twitter.com/3Softworks";
  }
}
